/*
All of our layer information in one place.
*/

import * as constants from './constants.js';
import * as sources from './sources.js';

// step expression for opacity sets it to 0 until the zoom level at which
// the static tiles no longer appear.
// setting minzoom somewhat lower than this allows some preloading
// minzoom: the zoom level at which this should reach as much opacity as it will get
// opacity: the opacity we want this layer to display with
function _fadeInLayer(minzoom, opacity = 0.3) {
    return ['step', ['zoom'], 0, minzoom, opacity];
}

// data layers that are always on
const dynamicTilesLayer = {
    id: 'eroadmap_poly',
    type: 'fill',
    source: sources.dynamicTileSource.id,
    minzoom: constants.staticHexTilesMaxZoom - 1,
    'source-layer': sources.dynamicTileSource.id,
    paint: {
        'fill-outline-color': constants.hexOutlineStyleRule,
        // step expression for opacity sets it to 0 until the zoom level at which
        // the static tiles no longer appear.
        // setting minzoom somewhat lower than this allows some preloading
        'fill-opacity': [
            'step',
            ['zoom'],
            0,
            constants.staticHexTilesMaxZoom,
            constants.roadHexFillOpacity,
        ],
    },
};
const staticTilesLayer = {
    id: 'eroadmap_static_polygons',
    type: 'fill',
    source: sources.staticTileSource.id,
    maxzoom: constants.staticHexTilesMaxZoom,
    'source-layer': sources.dynamicTileSource.id, // yes, I know, but this is how it has to work
    paint: {
        'fill-outline-color': constants.hexOutlineStyleRule,
        'fill-opacity': constants.roadHexFillOpacity,
    },
};
const dataLayers = [staticTilesLayer.id, dynamicTilesLayer.id]; // for convenience

// our extra layers that are not visible by default
const evChargingLayer = {
    id: 'ev_charging',
    type: 'circle',
    source: sources.ev_charging.id,
    'source-layer': sources.ev_charging.id,
    layout: {
        visibility: 'none',
    },

    paint: {
        'circle-color': [
            'step',
            ['get', 'dc_fast_ports'],
            constants.softBlack,
            1,
            constants.fastPortColor,
        ],
        'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            1,
            ['/', ['get', 'fast_plus_level2_ports'], 30],
            constants.maxMapZoom,
            ['+', ['sqrt', ['get', 'fast_plus_level2_ports']], 5],
        ],
    },
};
const truckStopsLayer = {
    id: 'truck_stops',
    type: 'circle',
    source: sources.truck_stops.id,
    'source-layer': sources.truck_stops.id,
    layout: {
        visibility: 'none',
    },
    paint: {
        'circle-color': constants.softBlack,
        'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            1,
            ['/', ['get', 'prkngcount'], 30],
            constants.maxMapZoom,
            ['+', ['sqrt', ['get', 'prkngcount']], 5],
        ],
    },
};
const hostingCapacityDetailLayer = {
    id: 'hosting_capacity_detail',
    type: 'line',
    source: sources.hosting_capacity_detail.id,
    'source-layer': sources.hosting_capacity_detail.id,
    minzoom: constants.hostingCapacityPolygonMaxZoom - 1,
    layout: {
        visibility: 'none',
    },
    paint: {
        'line-color': [
            'step',
            ['to-number', ['get', 'load_capacity']],
            constants.hostingCapacityColors[0],
            constants.hostingCapacityBreaks[0],
            constants.hostingCapacityColors[1],
            constants.hostingCapacityBreaks[1],
            constants.hostingCapacityColors[2],
            constants.hostingCapacityBreaks[2],
            constants.hostingCapacityColors[3],
            constants.hostingCapacityBreaks[3],
            constants.hostingCapacityColors[4],
        ],
        'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            1,
            1,
            constants.maxMapZoom,
            3,
        ],
        'line-opacity': _fadeInLayer(
            constants.hostingCapacityPolygonMaxZoom,
            1
        ),
    },
};
const hostingCapacityPolygonLayer = {
    id: 'hosting_capacity_polygon',
    type: 'fill',
    source: sources.hosting_capacity_polygon.id,
    'source-layer': sources.hosting_capacity_polygon.id,
    maxzoom: constants.hostingCapacityPolygonMaxZoom,
    layout: {
        visibility: 'none',
    },
    paint: {
        'fill-color': constants.softBlack,
        'fill-outline-color': constants.softBlack,
        'fill-opacity': constants.defaultFillOpacity,
    },
};
const hostingCapacityLayers = [
    hostingCapacityDetailLayer.id,
    hostingCapacityPolygonLayer.id,
];
const justice40StaticLayer = {
    id: 'justice40_static',
    type: 'fill',
    source: sources.justice40_static.id,
    'source-layer': sources.justice40_static.id,
    maxzoom: constants.staticCensusTractsTilesMaxZoom,
    layout: {
        visibility: 'none',
    },
    filter: ['get', 'justice40'],
    paint: {
        'fill-opacity': constants.defaultFillOpacity,
    },
};
const justice40DynamicLayer = {
    id: 'justice40_dynamic',
    type: 'fill',
    source: sources.justice40_dynamic.id,
    'source-layer': sources.justice40_dynamic.id,
    minzoom: constants.staticCensusTractsTilesMaxZoom - 1,
    layout: {
        visibility: 'none',
    },
    filter: ['get', 'justice40'],
    paint: {
        'fill-opacity': _fadeInLayer(constants.staticCensusTractsTilesMaxZoom),
    },
};
const justice40Layers = [justice40StaticLayer.id, justice40DynamicLayer.id];

const multiFamilyStaticLayer = {
    id: 'multifamily_static',
    type: 'fill',
    source: sources.acs_households_static.id,
    'source-layer': sources.acs_households_static.id,
    maxzoom: constants.staticCensusTractsTilesMaxZoom,
    layout: {
        visibility: 'none',
    },
    filter: ['>', ['get', 'mfd_percent'], constants.mfd_percent_MEDIAN],
    paint: {
        'fill-opacity': constants.defaultFillOpacity,
        'fill-color': constants.layerGradientColorRule(
            'mfd_percent',
            constants.mfd_percent_MEDIAN,
            1.0
        ),
        'fill-outline-color': constants.layerGradientColorRule(
            'mfd_percent',
            constants.mfd_percent_MEDIAN,
            1.0
        ),
    },
};
const multiFamilyDynamicLayer = {
    id: 'multifamily_dynamic',
    type: 'fill',
    source: sources.acs_households_dynamic.id,
    'source-layer': sources.acs_households_dynamic.id,
    minzoom: constants.staticCensusTractsTilesMaxZoom - 1,
    layout: {
        visibility: 'none',
    },
    filter: ['>', ['get', 'mfd_percent'], constants.mfd_percent_MEDIAN],
    paint: {
        'fill-opacity': _fadeInLayer(constants.staticCensusTractsTilesMaxZoom),
        'fill-color': constants.layerGradientColorRule(
            'mfd_percent',
            constants.mfd_percent_MEDIAN,
            1.0
        ),
        'fill-outline-color': constants.layerGradientColorRule(
            'mfd_percent',
            constants.mfd_percent_MEDIAN,
            1.0
        ),
    },
};
const multiFamilyLayers = [
    multiFamilyStaticLayer.id,
    multiFamilyDynamicLayer.id,
];
const pm25StaticLayer = {
    id: 'pm25_static',
    type: 'fill',
    source: sources.justice40_static.id,
    'source-layer': sources.justice40_static.id,
    maxzoom: constants.staticCensusTractsTilesMaxZoom,
    layout: {
        visibility: 'none',
    },
    filter: ['>', ['get', 'pm25'], constants.pm25_MIN_VALUE],
    paint: {
        'fill-opacity': constants.defaultFillOpacity,
        'fill-color': constants.layerGradientColorRule(
            'pm25',
            constants.pm25_MIN_VALUE,
            constants.pm25_MAX_VALUE
        ),
        'fill-outline-color': constants.layerGradientColorRule(
            'pm25',
            constants.pm25_MIN_VALUE,
            constants.pm25_MAX_VALUE
        ),
    },
};
const pm25DynamicLayer = {
    id: 'pm25_dynamic',
    type: 'fill',
    source: sources.justice40_dynamic.id,
    'source-layer': sources.justice40_dynamic.id,
    minzoom: constants.staticCensusTractsTilesMaxZoom - 1,
    layout: {
        visibility: 'none',
    },
    filter: ['>', ['get', 'pm25'], constants.pm25_MIN_VALUE],
    paint: {
        'fill-opacity': _fadeInLayer(constants.staticCensusTractsTilesMaxZoom),
        'fill-color': constants.layerGradientColorRule(
            'pm25',
            constants.pm25_MIN_VALUE,
            constants.pm25_MAX_VALUE
        ),
        'fill-outline-color': constants.layerGradientColorRule(
            'pm25',
            constants.pm25_MIN_VALUE,
            constants.pm25_MAX_VALUE
        ),
    },
};
const pm25Layers = [pm25StaticLayer.id, pm25DynamicLayer.id];
const transportationStaticLayer = {
    id: 'transportation_static',
    type: 'fill',
    source: sources.justice40_static.id,
    'source-layer': sources.justice40_static.id,
    maxzoom: constants.staticCensusTractsTilesMaxZoom,
    layout: {
        visibility: 'none',
    },
    filter: ['>=', ['get', 'trnsac_percentile'], 90],
    paint: {
        'fill-opacity': constants.defaultFillOpacity,
    },
};
const transportationDynamicLayer = {
    id: 'transportation_dynamic',
    type: 'fill',
    source: sources.justice40_dynamic.id,
    'source-layer': sources.justice40_dynamic.id,
    minzoom: constants.staticCensusTractsTilesMaxZoom - 1,
    layout: {
        visibility: 'none',
    },
    filter: ['>=', ['get', 'trnsac_percentile'], 90],
    paint: {
        'fill-opacity': _fadeInLayer(constants.staticCensusTractsTilesMaxZoom),
    },
};
const transportationLayers = [
    transportationStaticLayer.id,
    transportationDynamicLayer.id,
];
const costBurdenedHouseholdsStaticLayer = {
    id: 'cost_burdened_households_static',
    type: 'fill',
    source: sources.acs_households_static.id,
    'source-layer': sources.acs_households_static.id,
    maxzoom: constants.staticCensusTractsTilesMaxZoom,
    layout: {
        visibility: 'none',
    },
    filter: [
        '>',
        ['get', 'cost_burdened_percent'],
        constants.cost_burdened_MEDIAN,
    ],
    paint: {
        'fill-opacity': constants.defaultFillOpacity,
        'fill-color': constants.layerGradientColorRule(
            'cost_burdened_percent',
            constants.cost_burdened_MEDIAN,
            1.0
        ),
        'fill-outline-color': constants.layerGradientColorRule(
            'cost_burdened_percent',
            constants.cost_burdened_MEDIAN,
            1.0
        ),
    },
};
const costBurdenedHouseholdsDynamicLayer = {
    id: 'cost_burdened_households_dynamic',
    type: 'fill',
    source: sources.acs_households_dynamic.id,
    'source-layer': sources.acs_households_dynamic.id,
    minzoom: constants.staticCensusTractsTilesMaxZoom - 1,
    layout: {
        visibility: 'none',
    },
    filter: [
        '>',
        ['get', 'cost_burdened_percent'],
        constants.cost_burdened_MEDIAN,
    ],
    paint: {
        'fill-opacity': _fadeInLayer(constants.staticCensusTractsTilesMaxZoom),
        'fill-color': constants.layerGradientColorRule(
            'cost_burdened_percent',
            constants.cost_burdened_MEDIAN,
            1.0
        ),
        'fill-outline-color': constants.layerGradientColorRule(
            'cost_burdened_percent',
            constants.cost_burdened_MEDIAN,
            1.0
        ),
    },
};
const costBurdenedHouseholdsLayers = [
    costBurdenedHouseholdsStaticLayer.id,
    costBurdenedHouseholdsDynamicLayer.id,
];
const extraLayers = [
    evChargingLayer.id,
    truckStopsLayer.id,
    ...hostingCapacityLayers,
    ...justice40Layers,
    ...multiFamilyLayers,
    ...pm25Layers,
    ...transportationLayers,
    ...costBurdenedHouseholdsLayers,
];

export {
    costBurdenedHouseholdsDynamicLayer,
    costBurdenedHouseholdsLayers,
    costBurdenedHouseholdsStaticLayer,
    dataLayers,
    dynamicTilesLayer,
    evChargingLayer,
    extraLayers,
    hostingCapacityDetailLayer,
    hostingCapacityPolygonLayer,
    justice40DynamicLayer,
    justice40Layers,
    justice40StaticLayer,
    multiFamilyDynamicLayer,
    multiFamilyLayers,
    multiFamilyStaticLayer,
    pm25DynamicLayer,
    pm25Layers,
    pm25StaticLayer,
    staticTilesLayer,
    transportationDynamicLayer,
    transportationLayers,
    transportationStaticLayer,
    truckStopsLayer,
};
